import React from "react";
import {
  SimpleGrid,
  Image,
  VStack,
  Box,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";
import Preview from "../media/preview.gif";
import Placeholder from "./Placeholder";
import Minter from "./Minter";
import Bg from "../media/bg.png";

function Hero() {
  return (
    <Flex
      w="full"
      minH="100vh"
      align="center"
      backgroundImage={Bg}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <VStack maxW="1000px" ml="auto" mr="auto">
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={10} p={10}>
          <Box maxW="md">
            <Image src={Preview} borderRadius="lg" boxShadow="xl"></Image>
          </Box>
          <VStack spacing={5} alignSelf="center" align="flex-start">
            <Heading as="h2" size="4xl" color="pink.300">
              Axolols NFT
            </Heading>

            <Text fontSize="2xl" color="white">
              Axolols are 8,888 of the cutest, coolest, and silliest axolotls
              living on the Ethereum blockchain!
            </Text>
            <Heading as="h3" size="xl" color="pink.300">
              Save an Axolol, Save the World
            </Heading>
            <Text fontSize="2xl" color="white">
              Help save these critically endagered animals from extinction! 10%
              of proceeds will go towards various conservation efforts!
            </Text>
          </VStack>
        </SimpleGrid>
        <Placeholder />
      </VStack>
    </Flex>
  );
}

export default Hero;
