import React from "react";
import {
  Flex,
  Box,
  Stack,
  HStack,
  Heading,
  Spacer,
  Button,
  Icon,
  Image,
} from "@chakra-ui/react";
import { FaDiscord, FaTwitter, FaBars, FaTimes } from "react-icons/fa";
import { ReactComponent as OpenSea } from "../media/opensea.svg";
import Logo from "../media/AxololsLogo.png";

function Header() {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as="header"
      alignItems="center"
      color="pink.300"
      w="100%"
      wrap="wrap"
      p={2}
      pos="absolute"
      top="0"
      left="0"
      bgColor={{ base: "blue.500", md: "transparent" }}
    >
      <HStack>
        <Image src={Logo} w="64px" ml="20px"></Image>
        <Heading as="h1" size="2xl" fontFamily="Chewy">
          Axolols
        </Heading>
      </HStack>
      <Spacer />
      <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
        {show ? <FaTimes size={24} /> : <FaBars size={24} />}
      </Box>
      <Box
        display={{ base: show ? "flex" : "none", md: "flex" }}
        flexBasis={{ base: "100%", md: "auto" }}
        bgColor={{ base: show ? "blue.500" : "none", md: "none" }}
        justifyContent="center"
      >
        <Stack
          direction={["column", "column", "row", "row"]}
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Button
              colorScheme="pink"
              textColor="pink.300"
              variant="ghost"
              leftIcon={<FaDiscord />}
              fontSize="20px"
            >
              <a
                href="https://discord.gg/HTM2bpNkFa"
                target="_blank"
                aria-label="Discord"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </Button>
          </Box>
          <Box>
            <Button
              colorScheme="pink"
              textColor="pink.300"
              variant="ghost"
              leftIcon={<FaTwitter />}
              fontSize="20px"
            >
              <a
                href="https://twitter.com/axolols_nft"
                target="_blank"
                aria-label="Twitter"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </Button>
          </Box>
          <Box>
            <Button
              colorScheme="pink"
              textColor="pink.300"
              variant="ghost"
              leftIcon={<Icon as={OpenSea} />}
              fontSize="20px"
            >
              <a
                href="http://www.opensea.com"
                target="_blank"
                aria-label="OpenSea"
                rel="noopener noreferrer"
              >
                OpenSea
              </a>
            </Button>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
}

export default Header;
