import React from "react";
import About from "./About";
import Hero from "./Hero";
import Roadmap from "./Roadmap";
import Team from "./Team";
import { Flex } from "@chakra-ui/react";

function Content() {
  return (
    <Flex
      bgGradient="linear(to-b, blue.500, pink.600)"
      w="100%"
      pt="80px"
      pb="150px"
      minHeight="100vh"
      direction="column"
      align="center"
    >
      <Hero />
      <About />
      <Roadmap />
      <Team />
    </Flex>
  );
}

export default Content;
