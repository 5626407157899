import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import axo1 from "../media/1.png";
import axo2 from "../media/2.png";
import axo3 from "../media/3.png";
import axo4 from "../media/4.png";
import axo5 from "../media/5.png";
import axo6 from "../media/6.png";
import { Flex } from "@chakra-ui/react";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const items = [
  <img src={axo1} alt="axolol 1" width="300" height="300" />,
  <img src={axo2} alt="axolol 2" width="300" height="300" />,
  <img src={axo3} alt="axolol 3" width="300" height="300" />,
  <img src={axo4} alt="axolol 4" width="300" height="300" />,
  <img src={axo5} alt="axolol 5" width="300" height="300" />,
  <img src={axo6} alt="axolol 6" width="300" height="300" />,
];

function AutoCarousel() {
  return (
    <Flex w={{ base: "300px", md: "600px", lg: "900px" }}>
      <AliceCarousel
        autoPlay
        autoPlayStrategy="none"
        autoPlayInterval="2000"
        disableButtonsControls
        disableDotsControls
        infinite
        responsive={responsive}
        items={items}
      />
    </Flex>
  );
}

export default AutoCarousel;
