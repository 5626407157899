import React from "react";
import { Container, Image, VStack, Box, Heading, Text } from "@chakra-ui/react";
import Creator from "../media/creator.png";

function Team() {
  return (
    <Container maxW="container.lg" p={10}>
      <Heading as="h2" size="2xl" color="blue.200" pb="30px">
        Team
      </Heading>
      <Box
        w={["250px", "250px", "500px", "500px"]}
        p="6"
        display="flex"
        backgroundColor="pink.200"
        borderRadius="lg"
        boxShadow="xl"
        flexDirection={["column", "column", "row", "row"]}
      >
        <Image
          src={Creator}
          borderRadius="lg"
          boxShadow="xl"
          boxSize="200px"
        ></Image>
        <VStack spacing={5} align="flex-start" p="6">
          <Heading as="h2" size="lg" color="blue.400">
            Blitz
          </Heading>
          <Text fontSize="xl" color="gray.500">
            Chief Axolol
          </Text>
          <Text fontSize="xl" color="gray.500">
            Dev | Artist | Hype-man
          </Text>
        </VStack>
      </Box>
    </Container>
  );
}

export default Team;
