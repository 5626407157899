import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { ChakraProvider } from "@chakra-ui/react";
import Content from "./Components/Content";
import { theme } from "./styles/Theme";
import "@fontsource/ubuntu";
import "@fontsource/chewy";
import "@fontsource/dosis/700.css";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Content />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
