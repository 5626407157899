import React from "react";
import { Container, VStack, Image, Heading, Flex } from "@chakra-ui/react";
import RoadmapPic from "../media/roadmap4.png";

function Roadmap() {
  return (
    <Flex
      bgGradient="linear(to-b, blue.400, blue.700)"
      w="full"
      direction="column"
      align="center"
    >
      <Container maxW="container.lg" p={10}>
        <Heading as="h2" size="2xl" color="pink.300">
          Roadmap
        </Heading>
        <VStack align="center" spacing={5}>
          <Image src={RoadmapPic} boxSize="100%" objectFit="contain"></Image>
        </VStack>
      </Container>
    </Flex>
  );
}

export default Roadmap;
