import React from "react";
import { Flex, Box, Center, Icon, Text } from "@chakra-ui/react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { ReactComponent as OpenSea } from "../media/opensea.svg";

function Footer() {
  return (
    <Flex
      as="footer"
      align="center"
      color="pink.50"
      w="100%"
      h="150px"
      wrap="wrap"
      p={2}
      pos="absolute"
      bottom="0"
      left="0"
    >
      <Center w="100%" h="100px">
        <Box mx="25">
          <a
            href="https://discord.gg/HTM2bpNkFa"
            target="_blank"
            aria-label="Discord"
            rel="noopener noreferrer"
          >
            <FaDiscord size={50} />
          </a>
        </Box>
        <Box mx="25">
          <a
            href="https://twitter.com/axolols_nft"
            target="_blank"
            aria-label="Twitter"
            rel="noopener noreferrer"
          >
            <FaTwitter size={50} />
          </a>
        </Box>
        <Box mx="25">
          <a
            href="http://www.opensea.com"
            target="_blank"
            aria-label="OpenSea"
            rel="noopener noreferrer"
          >
            <Icon as={OpenSea} w={50} h={50} />
          </a>
        </Box>
      </Center>
      <Center w="100%">
        <Text fontSize="md">Contract Address: 0x</Text>
      </Center>
      <Center w="100%">
        <Text fontSize="xs">AXOLOLS © 2021</Text>
      </Center>
    </Flex>
  );
}

export default Footer;
