import React from "react";
import { Flex, Container, VStack, Heading, Text } from "@chakra-ui/react";
import AutoCarousel from "./AutoCarousel";

function About() {
  return (
    <Flex
      bgGradient="linear(to-b, pink.400, pink.600)"
      w="full"
      direction="column"
      align="center"
      pb="30px"
    >
      <Container maxW="container.lg" p={10}>
        <VStack align="flex-start" spacing={5}>
          <Heading as="h2" size="2xl" color="blue.200">
            Mission
          </Heading>
          <Text fontSize="2xl" color="white">
            Crypto + Conservation = 💚. 10% of this project's proceeds will be
            donated to various animal and environmental conservation groups that
            the community will vote on.
          </Text>
          <Text fontSize="2xl" color="white">
            Community is everything so another 10% will be used to support the
            Axolols community through giveaways and community project funding.
          </Text>
          <Heading as="h2" size="2xl" color="blue.200">
            Techy Stuff
          </Heading>
          <Text fontSize="2xl" color="white">
            Axolols are a collection of 8,888 hand-drawn and
            algorithmically-generated axolotl profile pictures that live on the
            Ethereum blockchain as ERC-721 tokens.
          </Text>
          <Text fontSize="2xl" color="white">
            Each image is 3000 x 3000 pixels and stored decentrally on IPFS.
          </Text>
          <Heading as="h2" size="2xl" color="blue.200">
            Minting
          </Heading>
          <Text fontSize="2xl" color="white">
            Axolols will be available to mint for 0.03 ETH (plus gas) at up to
            20 each time. There is no pre-sale and no price ramp.
          </Text>
          <Text fontSize="2xl" color="white">
            The creator has a reserve of 100 Axolols that will be used for
            promotion and member giveaways.
          </Text>
          <Heading as="h2" size="2xl" color="blue.200">
            Traits
          </Heading>
          <Text fontSize="2xl" color="white">
            Each axolol is generated from over 120+ individual traits with
            various rarities.
          </Text>
          <Text fontSize="2xl" color="white">
            A dashboard with rarity characteristics and rankings will be created
            after launch.
          </Text>
        </VStack>
      </Container>
      <AutoCarousel />
    </Flex>
  );
}

export default About;
